import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDxEG6huOR_evMmfyhXEuuaAtVztiHQXP4",
    authDomain: "home-shopping-list-9a89d.firebaseapp.com",
    projectId: "home-shopping-list-9a89d",
    storageBucket: "home-shopping-list-9a89d.appspot.com",
    messagingSenderId: "689872741679",
    appId: "1:689872741679:web:27c4053e3b1419a5846dda",
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
