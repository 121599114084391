export const getSuggestedItems = (userEmail) => {
    let suggestedItems = [];
  
    // Convert user email to lowercase for case-insensitive comparison
    const lowerCaseEmail = userEmail.toLowerCase();
  
    // Define suggested items based on the user's email
    switch (lowerCaseEmail) {
      case 'hadeed@gmail.com':
        suggestedItems = [
          { id: 1, name: "Greek Yogurt", quantity: 1, notes: "Low-fat" },
          { id: 2, name: "Skyr", quantity: 1, notes: "Protein-rich" },
          { id: 3, name: "Salad", quantity: 1, notes: "Mixed greens" },
        ];
        break;
      case 'dania@gmail.com':
        suggestedItems = [
          { id: 4, name: "Profiteroles", quantity: 1, notes: "Chocolate-filled" },
          { id: 5, name: "Meal deal", quantity: 1, notes: "Prawn Mayonnaise Sandwich" }
        ];
        break;
      case 'asma@gmail.com':
        suggestedItems = [
          { id: 6, name: "Eggs", quantity: 1, notes: "Cheap Ones" },
          { id: 7, name: "Milk", quantity: 1, notes: "1 Litre" },
          { id: 8, name: "Feta Cheese", quantity: 1, notes: "" },
          { id: 9, name: "Avacado", quantity: 1, notes: "" },
          { id: 10, name: "Fairy Liquid", quantity: 1, notes: "" },
          { id: 11, name: "Toilet Paper", quantity: 1, notes: "" },
          { id: 12, name: "Mayonnaise", quantity: 1, notes: "" },
          { id: 13, name: "Burger Buns", quantity: 1, notes: "" },
          { id: 14, name: "Salad", quantity: 1, notes: "" },
          { id: 15, name: "Teabags", quantity: 1, notes: "" },
          { id: 16, name: "Fairy Dishwasher Tablets", quantity: 1, notes: "" },
        ];
        break;
      default:
        // Default suggested items for other users
        suggestedItems = [];
        break;
    }
  
    return suggestedItems;
  };
  