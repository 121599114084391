import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import { getSuggestedItems } from './suggestedItems'; // Import the function
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './App.css'; // Import your custom CSS for additional styles
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, onSnapshot, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { FaPlus, FaPen, FaTrash, FaCheck, FaXmark, } from 'react-icons/fa6';
import { FaUnlock, FaLock } from "react-icons/fa";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxEG6huOR_evMmfyhXEuuaAtVztiHQXP4",
  authDomain: "home-shopping-list-9a89d.firebaseapp.com",
  projectId: "home-shopping-list-9a89d",
  storageBucket: "home-shopping-list-9a89d.appspot.com",
  messagingSenderId: "689872741679",
  appId: "1:689872741679:web:27c4053e3b1419a5846dda",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [notes, setNotes] = useState('');
  const [items, setItems] = useState([]);
  const [editedItemName, setEditedItemName] = useState('');
  const [editedQuantity, setEditedQuantity] = useState('');
  const [editedNotes, setEditedNotes] = useState('');
  const [editItemId, setEditItemId] = useState(null);
  const [suggestedItems, setSuggestedItems] = useState([]);

  // Fetch items from Firestore
  useEffect(() => {
    const fetchItems = async () => {
      const itemsCollection = collection(firestore, 'items');
      const unsubscribe = onSnapshot(itemsCollection, (snapshot) => {
        const itemsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemsData);
      });
      return unsubscribe;
    };
    fetchItems();
  }, []);
// Fetch suggested items based on the logged-in user's email
useEffect(() => {
  if (user && user.email) {
    const userEmail = user.email;
    const currentUserSuggestedItems = getSuggestedItems(userEmail);
    setSuggestedItems(currentUserSuggestedItems);
  }
}, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  

  // Calculate number of bought items
  const numBoughtItems = items.filter(item => item.isBought).length;
  const numTotalItems = items.length;

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    // Convert timestamp to Date object
    const date = timestamp.toDate();

    // Format date and time
    const formattedDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const handleAddItem = async (item) => {
    try {
      // Check if item name and quantity are not empty
      if (!item.name || !item.quantity) {
        alert('Please enter both item name and quantity.');
        return;
      }
  
      // Find existing item with the same name and notes
      const existingItemIndex = items.findIndex(existingItem => existingItem.itemName === item.name && existingItem.notes === item.notes);
  
      if (existingItemIndex !== -1) {
        // If the item already exists with the same name and notes, update its quantity
        const existingItem = items[existingItemIndex];
        const newQuantity = existingItem.quantity + parseInt(item.quantity, 10); // Parse quantity as an integer
  
        const itemDocRef = doc(firestore, 'items', existingItem.id);
        await updateDoc(itemDocRef, { quantity: newQuantity });
      } else {
        // Add new item with user's display name
        const timestamp = serverTimestamp();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            console.log("Current User:", user);
            const { email } = user;
            await addDoc(collection(firestore, 'items'), {
              itemName: item.name,
              quantity: parseInt(item.quantity, 10),
              notes: item.notes,
              addedBy: email, // Use user's email
              timestamp: timestamp
            });
            // Clear input fields after adding item
            setItemName('');
            setQuantity('');
            setNotes('');
          } else {
            console.error('User not logged in');
          }
  
          // Unsubscribe from onAuthStateChanged listener to avoid memory leaks
          unsubscribe();
        });
      }
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  };
  
  
  const handleDeleteItem = async (itemId) => {
    try {
      // Get the item from the items array
      const itemToDelete = items.find(item => item.id === itemId);
  
      // Check if the item exists
      if (!itemToDelete) {
        console.error("Item not found.");
        return;
      }
  
      // Check if the item belongs to the current user
      if (itemToDelete.addedBy !== user.email) {
        // Show an error message to the user
        alert('You can only delete your own items.');
        return;
      }
  
      // Update timestamp
      const timestamp = serverTimestamp();
      const itemDocRef = doc(firestore, 'items', itemId);
      await updateDoc(itemDocRef, { timestamp: timestamp });
      await deleteDoc(itemDocRef);
    } catch (error) {
      console.error("Error deleting item: ", error);
    }
  };
  

  const handleUpdateItem = async (itemId, newItemName, newQuantity, newNotes) => {
    try {
      // Check if new quantity is not empty
      if (!newQuantity) {
        alert('Please enter the new quantity.');
        return;
      }
  
      // Get the item from the items array
      const itemToUpdate = items.find(item => item.id === itemId);
  
      // Check if the item exists
      if (!itemToUpdate) {
        console.error("Item not found.");
        return;
      }
  
      // Check if the item belongs to the current user
      if (itemToUpdate.addedBy !== user.email) {
        // Show an error message to the user
        alert('You can only update the quantity of items added by you.');
        return;
      }
  
      // Update timestamp
      const timestamp = serverTimestamp();
      const itemDocRef = doc(firestore, 'items', itemId);
      await updateDoc(itemDocRef, {
        itemName: newItemName, // Update item name if needed
        quantity: newQuantity,
        notes: newNotes, // Update notes
        timestamp: timestamp
      });
  
      // Reset edit state
      setEditItemId(null);
      setEditedItemName('');
      setEditedQuantity('');
      setEditedNotes('');
    } catch (error) {
      console.error("Error updating item: ", error);
    }
  };
  
  
  
  
  const getNameFromEmail = (email) => {
    if (!email) return '';
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
      return email.substring(0, atIndex);
    } else {
      return email;
    }
  };

  const handleMarkAsBought = async (itemId, isBought) => {
    try {
      const itemDocRef = doc(firestore, 'items', itemId);
      await updateDoc(itemDocRef, {
        isBought: !isBought // Toggle isBought status
      });
    } catch (error) {
      console.error("Error marking item as bought: ", error);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/app" /> : <Navigate to="/login" />} />
        <Route path="/login" element={user ? <Navigate to="/app" /> : <Login />} />
        <Route path="/app" element={user ? (
          <div className="container mt-4">
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Item Name" value={itemName} onChange={(e) => setItemName(e.target.value)} />
              <input type="number" className="form-control" placeholder="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
              <input type="text" className="form-control" placeholder="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
              <div className="action-buttons"> {/* Container for action buttons */}
  <button className="btn btn-primary" onClick={() => handleAddItem({ name: itemName, quantity: quantity, notes: notes })}><FaPlus /></button>
</div>

            </div>
            <h1 className="mt-4 mb-3">Shopping List <span className={`badge ${numBoughtItems === numTotalItems ? 'bg-success' : 'bg-primary'}`}>{numBoughtItems}/{numTotalItems}</span></h1>
            <div className="mt-3">
              <span className="text-muted">Last Updated:</span>
              <span className="ms-2">{formatTimestamp(items.length > 0 ? items[0].timestamp : null)}</span>
            </div>
            <h2 className="mb-3">Suggested Items</h2>
            <div className="suggested-items-container">
              <div className="suggested-items">
                {/* Display suggested items */}
                {suggestedItems.map(item => (
                  <button key={item.id} className="btn btn-outline-primary d-inline-flex align-items-center me-2" onClick={() => handleAddItem(item)}>
                    <FaPlus className="me-1" /> {item.name}
                  </button>
                ))}
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Notes</th>
                  <th>Added By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map(item => (
                  <tr key={item.id} className={item.isBought ? "bought" : ""}>
                    <td>
                      {editItemId === item.id ? (
                        <input type="text" className="form-control" value={editedItemName} onChange={(e) => setEditedItemName(e.target.value)} />
                      ) : (
                        <span>{item.itemName}</span>
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input type="number" className="form-control" value={editedQuantity} onChange={(e) => setEditedQuantity(e.target.value)} />
                      ) : (
                        <span>{item.quantity}</span>
                      )}
                    </td>
                    <td>
                      {editItemId === item.id ? (
                        <input type="text" className="form-control" value={editedNotes} onChange={(e) => setEditedNotes(e.target.value)} />
                      ) : (
                        <span>{item.notes}</span>
                      )}
                    </td>
                    <td>{getNameFromEmail(item.addedBy)}</td>
                    <td>
                      {editItemId === item.id ? (
                        <React.Fragment>
                          <div className="d-flex">
                            <button className="btn btn-primary me-2 btn-action" onClick={() => handleUpdateItem(item.id, editedItemName, editedQuantity, editedNotes)}><FaCheck /></button>
                            <button className="btn btn-secondary btn-action" onClick={() => setEditItemId(null)}><FaXmark /></button>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="d-flex">
                          <button className="btn btn-warning me-2 btn-action" onClick={() => { setEditedItemName(item.itemName); setEditedQuantity(item.quantity); setEditedNotes(item.notes); setEditItemId(item.id); }}><FaPen /></button>
                          <button className="btn btn-danger me-2 btn-action" onClick={() => handleDeleteItem(item.id)}><FaTrash /></button>
                          <button className="btn btn-success me-2 btn-action" onClick={() => handleMarkAsBought(item.id, item.isBought)}>{item.isBought ? <><FaUnlock /></> : <><FaLock /></>}</button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
